import { useTranslation } from "react-i18next";

import { assets } from "../conf";

type OutdatedProps = {};

function Outdated(_: OutdatedProps) {
  const { t } = useTranslation("common");
  return (
    <div className="pv5 flex flex-column flex-nowrap items-center justify-center">
      <div className="pv5 flex flex-row flex-nowrap items-center justify-center">
        <img width={75} height={75} src={assets.mark} alt={assets.alt} />
      </div>
      <div className="JoinArthur--CenteredFormCard">
        <div className="bg-white ba b--polaris-gray br2">
          <div className="pa4">
            <h1 className="lh-title fw6 f1 mt0 mb3">
              {t("pages.outdated.outdated-browser")}
            </h1>
            <p className="f3 lh-copy ma0">
              {t("pages.outdated.this-browser-is-not")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Outdated;
